





























import Vue from 'vue'
import InputInTwoDecimals from '@components/input/currency-input.vue'
import AccountList from '../components/AccountList.vue'
import { truncate } from '@utils/common-methods/numberFormatter'
import { mapState } from 'vuex'
import { withdrawFunds } from '@api/addy-plus/wallet'
import { IData, IMethods, IComputed } from '@/types/addy_plus/corporations/wallet/WithdrawFunds'
import { AccountInfo } from '@/types/addy_plus/corporations/wallet/AccountList'

export default Vue.extend<IData, IMethods, IComputed>({
  data() {
    return {
      inputHasError: false,
      selectedAccount: '',
      amount: 0,
      isWithdrawingFunds: false,
      showErrorFlash: false,
      errorFlashMessage: '',
    }
  },
  computed: {
    ...mapState('addPlusEntityFunds', ['walletBalance', 'connectedAccounts']),
    accountId() {
      return this.$route.params.accountId
    },
    corporationId() {
      return this.$route.params.corporationId
    },
    truncatedAvailableFunds() {
      return truncate(this.walletBalance, 2)
    },
    errorMessage() {
      let message = 'Sorry, the minimum withdrawal amount is $5.00'
      if (Number(this.amount) > Number(this.truncatedAvailableFunds)) message = 'Sorry, the withdrawal amount exceeds your available wallet balance.'
      return message
    },
  },
  components: {
    InputInTwoDecimals,
    AccountList,
  },
  watch: {
    connectedAccounts(value) {
      if (value.length > 0) {
        this.selectedAccount = value.find((account: AccountInfo) => account.status === 'verified')?.hashid
      }
    },
  },
  methods: {
    onInput(value) {
      this.amount = value
      this.validateAmount()
    },
    validateAmount() {
      this.inputHasError = (Number(this.amount) > Number(this.truncatedAvailableFunds)) || (Number(this.amount) < 5)
    },
    onWithdrawFunds() {
      this.isWithdrawingFunds = true
      withdrawFunds(this.corporationId, this.amount, this.selectedAccount).then((response) => {
        this.isWithdrawingFunds = false
        if (!response.success) {
          this.errorFlashMessage = response.error || ''
          this.showErrorFlash = true
        } else this.$router.push(`/addyPlus/${this.accountId}/corporations/${this.corporationId}/wallet`)
      })
    },
  },
})
